import * as CookieStore from 'libs/storage/CookieStore';
import * as ls from 'local-storage';
import { v4 as uuidv4 } from 'uuid';

// Because Cookies can be unreliable, e.g. inside of embedded Instagram Browsers on iOS, which are hard to repro
// and hard to control / debug, we're using a hierarchical fallback mechanism for the device_id.
// We have a Cookie > LocalStorage > URL Param fallback waterfall.
export const DeviceIdKeyForCookie = 'device_id';
const DeviceIdKey = 'mental_device_id';
const saveDeviceId = (deviceId: string) => {
  CookieStore.set(DeviceIdKeyForCookie, deviceId, { domain: '.getmental.com' });
  ls.set(DeviceIdKey, deviceId);
};

// should only ever be referenced once, before the first time we try to initialize and set it to the SecureStore
const firstTimeEverDeviceId = uuidv4();

export const getDeviceId = (): string => {
  const deviceIdAndSource = getDeviceIdAndSources();
  return deviceIdAndSource.deviceId;
};

const deviceIdSources: string[] = [];
export const getDeviceIdAndSources = ():{ deviceId: string, sources: string[] } => {
  const deviceIdAndSource = getRawDeviceIdAndSource();
  deviceIdSources.push(deviceIdAndSource.source);
  return { deviceId: deviceIdAndSource.deviceId, sources: deviceIdSources };
};

const getQueryParam = () => {
  if (typeof window !== 'undefined') {
    const urlParamValue = new URLSearchParams(window.location.search).get(DeviceIdKey);
    return urlParamValue ?? undefined;
  }
  return undefined;
};

export type InitialDeviceIdValues = {
  cookie: string | undefined,
  local_storage: string | undefined,
  query_param: string | undefined,
  generated_uuid: string,
};

const getAllDeviceIdValues = (): InitialDeviceIdValues => {
  const cookie = CookieStore.get(DeviceIdKeyForCookie);
  const local_storage = ls.get<string>(DeviceIdKey);
  const query_param = getQueryParam();
  const generated_uuid = firstTimeEverDeviceId;

  return {
    cookie,
    local_storage,
    query_param,
    generated_uuid,
  };
};

let initialDeviceIdValues: InitialDeviceIdValues | undefined;

const getRawDeviceIdAndSource = ():{ deviceId: string, source: string } => {
  const deviceIdValues = getAllDeviceIdValues();
  const {
    cookie, local_storage, query_param, generated_uuid,
  } = deviceIdValues;
  if (!initialDeviceIdValues) {
    initialDeviceIdValues = deviceIdValues;
  }

  if (cookie) return { deviceId: cookie, source: 'cookie' };
  if (local_storage) return { deviceId: local_storage, source: 'local_storage' };
  if (query_param) return { deviceId: query_param, source: 'query_param' };
  return { deviceId: generated_uuid, source: 'generated_uuid' };
};

export const getInitialDeviceIdValues = () => {
  return initialDeviceIdValues;
};

const tryToInitializeDeviceId = () => {
  const deviceId = getDeviceId();
  saveDeviceId(deviceId);
};

tryToInitializeDeviceId();
