import 'styles/globals.css';

import { GoogleOAuthProvider } from '@react-oauth/google';
import FacebookPixel from 'components/analytics/FacebookPixel';
import GTag from 'components/analytics/GTag';
import TikTokPixel from 'components/analytics/TikTokPixel';
import XPixel from 'components/analytics/XPixel';
import withErrorBoundary from 'hooks/useErrorBoundary';
import type { AppProps } from 'next/app';
import React, { useEffect, useState } from 'react';

const { NEXT_PUBLIC_GOOGLE_OAUTH_CLIENT_ID } = process.env;
const { NEXT_PUBLIC_FACEBOOK_PIXEL_ID } = process.env;
const { NEXT_PUBLIC_TIKTOK_PIXEL_ID } = process.env;
const { NEXT_PUBLIC_X_PIXEL_ID } = process.env;
const { NEXT_PUBLIC_GA_TRACKING_ID } = process.env;
// const NEXT_PUBLIC_ONESIGNAL_APP_ID = process.env.NEXT_PUBLIC_ONESIGNAL_APP_ID;
// const NEXT_PUBLIC_ONESIGNAL_SAFARI_WEB_ID = process.env.NEXT_PUBLIC_ONESIGNAL_SAFARI_WEB_ID;

const MyApp = ({ Component, pageProps }: AppProps) => {
  const [isOneSignalReady, setIsOneSignalReady] = useState(false);
  useEffect(() => {
    (async () => {
      if (process.env.NODE_ENV === 'development') {
        setIsOneSignalReady(true);
        return;
      }

      setIsOneSignalReady(true);
    })();
  }, []);

  const WrappedComponent = withErrorBoundary(Component);

  return (
    <GoogleOAuthProvider clientId={`${NEXT_PUBLIC_GOOGLE_OAUTH_CLIENT_ID}`}>
      {NEXT_PUBLIC_FACEBOOK_PIXEL_ID && <FacebookPixel pixelId={`${NEXT_PUBLIC_FACEBOOK_PIXEL_ID}`} />}
      {NEXT_PUBLIC_TIKTOK_PIXEL_ID && <TikTokPixel pixelId={`${NEXT_PUBLIC_TIKTOK_PIXEL_ID}`} />}
      {NEXT_PUBLIC_X_PIXEL_ID && <XPixel pixelId={`${NEXT_PUBLIC_X_PIXEL_ID}`} />}
      {NEXT_PUBLIC_GA_TRACKING_ID && <GTag />}
      {isOneSignalReady && <WrappedComponent {...pageProps} />}
    </GoogleOAuthProvider>
  );
};

export default MyApp;
