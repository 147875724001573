import MentalButton from 'components/foundation/buttons/MentalButton';
import { logOutUser, useVerifiedUser } from 'models/user';
import { useRouter } from 'next/router';
import React from 'react';
import MentalLettermark from 'resources/svgs/MentalLettermark';
import grid from 'styles/grid';
import pallette from 'styles/pallette';

const Header = () => {
  const router = useRouter();
  const lettermarkHeight = 43;
  const paddingVertical = grid.Small;
  const headerHeight = lettermarkHeight + 2 * paddingVertical;

  const user = useVerifiedUser();

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'center',
          position: 'fixed',
          width: '100vw',
          zIndex: 999,
          top: 0,
          height: headerHeight,
          backgroundColor: pallette.neutral.w1000,
        }}
      >
        <a
          href="/"
        >
          <MentalLettermark height={lettermarkHeight} />
        </a>
        {user?.is_verified && (
          <MentalButton
            role="link"
            style={{ textAlign: 'right' }}
            containerClassName="absolute w-auto p-0 right-0 text-right"
            onPress={() => {
              logOutUser();
              router.push('/login');
            }}
            title="Log out"
          />
        )}
      </div>
      <div style={{ width: '100vw', height: headerHeight }} />
    </>
  );
};
export default Header;
